import clsx from "clsx";
import { Link } from "react-router-dom";
import { Loading, LoaderTypes } from "../Loading";

const buttonStyles = {
  solidSlate:
    "group inline-flex items-center justify-center rounded-full py-4 px-6 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900",
  solidBlue:
    "group inline-flex items-center justify-center rounded-full py-4 px-6 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600",
  solidScarRed:
    "group inline-flex items-center justify-center rounded-full py-4 px-6 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-scarRed text-white hover:text-slate-100 hover:bg-scarRed-500 active:bg-scarRed-800 active:text-scarRed-100 focus-visible:outline-scarRed-600",
  solidScarBlue:
    "group inline-flex items-center justify-center rounded-full py-4 px-6 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-scarBlue text-white hover:text-slate-100 hover:bg-scarLightBlue active:bg-scarBlue-800 active:text-scarBlue-100 focus-visible:outline-scarBlue-600",
  solidWhite:
    "group inline-flex items-center justify-center rounded-full py-4 px-6 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white",
  outlineSlate:
    "group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300",
  outlineWhite:
    "group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none ring-slate-700 text-slate-700 hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white",
};

interface ButtonProperties {
  className?: string;
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
  variant:
    | "solidSlate"
    | "solidBlue"
    | "solidScarRed"
    | "solidScarBlue"
    | "solidWhite"
    | "outlineSlate"
    | "outlineWhite";
  to?: string;
  isLoading?: boolean;
  loadingColour?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const Button = (properties: ButtonProperties): JSX.Element => {
  const {
    children,
    type,
    variant,
    to,
    isLoading,
    loadingColour,
    onClick,
    disabled,
  } = properties;

  const disabledClass = disabled ? "opacity-50 cursor-not-allowed" : "";

  const className = clsx(
    buttonStyles[variant],
    properties.className,
    disabledClass,
  );

  return to ? (
    <Link to={to} className={className}>
      {isLoading && (
        <Loading
          loaderType={LoaderTypes.Spinner}
          colour={loadingColour}
        ></Loading>
      )}
      {children}
    </Link>
  ) : (
    <button
      type={type || "button"}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {isLoading && (
        <Loading
          loaderType={LoaderTypes.Spinner}
          colour={loadingColour}
        ></Loading>
      )}
      {children}
    </button>
  );
};
