import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export const Legal = (): JSX.Element => {
  return (
    <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
      <div className="flex gap-x-6">
        <a
          href="https://www.legacy.careeranchorsonline.com/SCA/startPage.do"
          target="_blank"
          className="text-sm hover:underline text-slate-500"
        >
          <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />
          Legacy Site
        </a>
        <a
          href="https://www.wiley.com/terms-of-use"
          target="_blank"
          className="text-sm hover:underline text-slate-500"
        >
          <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />
          Terms of Use
        </a>
        <a
          href="https://www.wiley.com/privacy"
          target="_blank"
          className="text-sm hover:underline text-slate-500"
        >
          <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />
          Privacy Policy
        </a>
      </div>
      <p className="mt-6 text-sm text-slate-500 sm:mt-0">
        Copyright &copy; {new Date().getFullYear()} John Wiley & Sons, Inc. All
        rights reserved.
      </p>
    </div>
  );
};
