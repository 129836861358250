import axios, { AxiosError, AxiosRequestConfig } from "axios";
import environment_ from "../env";
import { CodeActivationResponse } from "../types/CodeActivation";
import { Profile, UpdateProfileRequest } from "../types/Profile";
import { Results } from "../types/Results";
import { GetInvitesResponse, InvitationRequest } from "../types/Invitation";
import { useAuth } from "oidc-react";
import { ApiResult } from "../types/ApiResult";
import { GetDepositsResponse, TokenDeposit } from "../types/TokenDeposit";
import { useNavigate } from "react-router-dom";
import { DashboardResult } from "../types/DashboardResult";

const BaseUrl = environment_.SCAR_API_BASE;

export const scarAxios = axios.create({
  baseURL: BaseUrl,
  paramsSerializer: {
    indexes: true,
  },
});

export function ApiIntegration(): JSX.Element {
  const navigate = useNavigate();
  const auth = useAuth();

  const requestErrorInterceptor = (error: AxiosError) => {
    // If it's not Axios related error (network issue, etc.)
    if (!axios.isAxiosError(error)) {
      console.warn("An unexpected error occurred:", error);
      return Promise.reject(error);
    }

    if (error.response) {
      switch (error.response.status) {
        // Not authenticated / Session expired
        case 401: {
          console.warn("You are not authenticated.");
          auth.signOut();
          navigate("/");
          break;
        }
        // Not authorized / Forbidden
        case 403: {
          console.warn("You are not authorized to perform this operation.");
          navigate("/");
          break;
        }
        case 400: {
          console.warn("A validation error occurred.", error.response.data);
          break;
        }
        default: {
          console.warn(
            "An error occurred while performing your operation:",
            error.response.data,
          );
        }
      }
    } else {
      // Response is undefined, could be a request setup issue
      console.warn("A request configuration error occurred:", error.message);
    }

    // if you want to proceed with the default error handling
    return Promise.reject(error);
  };

  const requestInterceptor = (config: AxiosRequestConfig) => {
    // if not logged in, just return the config
    if (!auth.userData) return config;

    // if the token is expired, force logout
    if (auth.userData.expired) {
      auth.signOut();
      return config;
    }

    if (config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${auth.userData?.access_token}`;
    }

    return config;
  };

  scarAxios.interceptors.request.use(
    requestInterceptor,
    requestErrorInterceptor,
  );

  return <></>;
}

// application endpoints
export const getProfile = async (): Promise<Profile> => {
  const result = await scarAxios.get<Profile>(`/v1/profile`);
  return result.data;
};

export const updateProfile = async (
  updateProfileRequest: UpdateProfileRequest,
): Promise<Profile> => {
  const result = await scarAxios.post("/v1/profile", updateProfileRequest);
  return result.data;
};

export const activateCode = async (
  code: string,
): Promise<CodeActivationResponse> => {
  try {
    const result = await scarAxios.post<ApiResult>(
      "/v1/code/activate",
      undefined,
      {
        params: { code: code },
      },
    );

    return {
      success: true,
      message: result.data.message,
    };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        return {
          success: false,
          message: "The provided code is invalid or incorrect.",
        };
      }
      return {
        success: false,
        message: error.response.data.message as string,
      };
    } else {
      // it's not axios related
      return { success: false, message: error as string };
    }
  }
};

export const getResults = async (): Promise<Results> => {
  const result = await scarAxios.get<Results>("/v1/results");
  return result.data;
};

export const submitAnchorsResults = async (
  results: Results,
): Promise<boolean> => {
  const result = await scarAxios.put("/v1/anchor/results", results);
  if (result.data.success) {
    return true;
  }
  return false;
};

export const submitGrowthResults = async (
  results: Results,
): Promise<boolean> => {
  const result = await scarAxios.put("/v1/growth/results", results);
  if (result.data.success) {
    return true;
  }
  return false;
};

// partner tokens management endpoints

// partner management endpoints
export const sendInvitations = async (
  invitationRequest: InvitationRequest,
): Promise<boolean> => {
  // take the pairs from the request and spin out an array of { name, email, dueBy, tag }
  const invitations = invitationRequest.pairs.map(pair => {
    return {
      email: pair.email,
      name: pair.name,
      dueBy: invitationRequest.dueBy,
      tag: invitationRequest.tag,
    };
  });

  const result = await scarAxios.post("/partner/invite", invitations);
  if (result.data.success) {
    return true;
  }
  return false;
};

export const getInvitations = async (
  page: number,
): Promise<GetInvitesResponse> => {
  const result = await scarAxios.get<GetInvitesResponse>("/partner/invites", {
    params: {
      page: page,
    },
  });
  return result.data;
};

export const searchInvitations = async (
  page: number,
  query: string,
): Promise<GetInvitesResponse> => {
  const result = await scarAxios.get<GetInvitesResponse>(
    "/partner/invites/search",
    {
      params: {
        page: page,
        input: query,
      },
    },
  );
  return result.data;
};

export const getInviteResults = async (invitationId: number) => {
  const result = await scarAxios.get("/partner/results", {
    params: {
      invitationId: invitationId,
    },
  });

  return result.data;
};

export const unInvite = async (
  invitationId: number,
): Promise<boolean | string> => {
  try {
    const result = await scarAxios.post("/partner/uninvite", undefined, {
      params: { invitationId: invitationId },
    });
    if (result.data.success) {
      return true;
    }
    return false;
  } catch (error) {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data.message as string;
    } else {
      return error as string;
    }
  }
};

export const sendReminder = async (
  invitationId: number,
): Promise<boolean | string> => {
  try {
    const result = await scarAxios.post("/partner/remind", undefined, {
      params: { invitationId: invitationId },
    });
    if (result.data.success) {
      return true;
    }
    return false;
  } catch (error) {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data.message as string;
    } else {
      return error as string;
    }
  }
};

export const getDeposits = async (
  page: number,
): Promise<GetDepositsResponse> => {
  const result = await scarAxios.get<GetDepositsResponse>("/wiley/deposits", {
    params: {
      page: page,
    },
  });
  return result.data;
};

export const searchDeposits = async (
  page: number,
  query: string,
): Promise<GetDepositsResponse> => {
  const result = await scarAxios.get<GetDepositsResponse>(
    "/wiley/deposits/search",
    {
      params: {
        page: page,
        email: query,
      },
    },
  );
  return result.data;
};

export const createDeposit = async (
  tokenDeposit: TokenDeposit,
): Promise<GetDepositsResponse> => {
  const result = await scarAxios.post("/wiley/deposit", tokenDeposit);
  return result.data;
};

export const revokeDeposit = async (depositId: string) => {
  const result = await scarAxios.delete("/wiley/deposit", {
    params: {
      depositId,
    },
  });
  return result.data;
};

export const resetAccount = async (email: string) => {
  const result = await scarAxios.delete("/wiley/reset", {
    params: {
      email,
    },
  });
  return result.data;
};

export const deleteAccount = async (email: string) => {
  const result = await scarAxios.delete("/wiley/delete", {
    params: {
      email,
    },
  });
  return result.data;
};

export const getDashboard = async (): Promise<DashboardResult> => {
  const result = await scarAxios.get<DashboardResult>("/wiley/dashboard");
  return result.data;
};
