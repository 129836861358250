import { AnchorTimelineType, Question, QuestionType } from "../types/Question";
import { AnchorType } from "../types/Anchor";

// shuffle question bank
export const shuffleQuestions = (array: Question[]) => {
  const newArray = [...array];
  for (let index = newArray.length - 1; index > 0; index--) {
    const secondaryIndex = Math.floor(Math.random() * (index + 1));
    [newArray[index], newArray[secondaryIndex]] = [
      newArray[secondaryIndex],
      newArray[index],
    ];
  }
  return newArray;
};

const AnchorQuestionStorageString = "scar.questions.anchors";
const GrowthQuestionStorageString = "scar.questions.growth";

// these are static and don't change
export const AnchorQuestionBank: Question[] = [
  //
  // Anchor Questions - Assessment 1
  //

  // Technical Functional (TF)
  {
    text: "I have always felt most successful and accomplished when I could use my specialized training/expertise in my work",
    anchorType: AnchorType.TF,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "Being a senior functional manager in my area of expertise is more important than becoming a general manager",
    anchorType: AnchorType.TF,
    anchorTimelineType: AnchorTimelineType.Current,

    questionType: QuestionType.Anchors,
  },
  {
    text: "I will feel successful in the future if I am truly able to build my success on my experience, knowledge and expertise",
    anchorType: AnchorType.TF,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I look forward to being so highly skilled at what I do that others will seek my expert advice",
    anchorType: AnchorType.TF,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },

  // Autonomy (AU)
  {
    text: "I am most fulfilled in my work when I am completely free to define my own tasks, schedules, and procedures",
    anchorType: AnchorType.AU,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I would consider leaving a job if I felt like my manager was exerting too much control over my work style and independence",
    anchorType: AnchorType.AU,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I dream of a career that will allow me the freedom to work in my own way and on my own schedule",
    anchorType: AnchorType.AU,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I would rather leave my organization than accept a position that would reduce my autonomy or limit my ability to work in my own way",
    anchorType: AnchorType.AU,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },

  // Challenge And Risk (C&R)
  {
    text: "I am most fulfilled in my work when I am able to solve seemingly insurmountable problems or succeed in the face of seemingly impossible odds",
    anchorType: AnchorType.CR,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I prefer work opportunities that strongly challenge my problem-solving, risk-seeking and competitive abilities",
    anchorType: AnchorType.CR,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I dream of a career in which I will always face increasingly more difficult problems and high stakes situations",
    anchorType: AnchorType.CR,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I am looking forward to having a position that is very high risk with the possibility of a very big pay day",
    anchorType: AnchorType.CR,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },

  // Entrepreneurial Creativity (EC)
  {
    text: "I am always on the lookout for ideas that motivate me to start my own business",
    anchorType: AnchorType.EC,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I feel most fulfilled when I have been able to achieve success that is primarily the result of my own initiative",
    anchorType: AnchorType.EC,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I dream of starting up and building my own business",
    anchorType: AnchorType.EC,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },
  {
    text: "Building a business of my own over time will be more important to me than becoming successful in someone else's company",
    anchorType: AnchorType.EC,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },

  // General Management (GM)
  {
    text: "Being a general manager is more attractive to me than being an expert in a particular technical domain",
    anchorType: AnchorType.GM,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I am most happy when I make progress on a track toward general management",
    anchorType: AnchorType.GM,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I dream of being in charge of an entire organization",
    anchorType: AnchorType.GM,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I will feel I have achieved success when I become a senior-level general manager in a medium-sized or large organization",
    anchorType: AnchorType.GM,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },

  // Service, Dedication To A Cause (SV)
  {
    text: "I feel most fulfilled in my work when I have been able to use my talents in the service of others",
    anchorType: AnchorType.SV,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I am happy in my current position because I am making tangible contributions to humanity and society",
    anchorType: AnchorType.SV,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "Using my talents to make the world a better place to live and work is what has and will drive my career decisions",
    anchorType: AnchorType.SV,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I dream of finding a position that allows me to prioritize service to others over simple financial gain for me",
    anchorType: AnchorType.SV,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },

  // Stability And Security (SE)
  {
    text: "Security and stability are generally more important to me than freedom and independence",
    anchorType: AnchorType.SE,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I am most fulfilled in my work life when I feel that I have financial and job security",
    anchorType: AnchorType.SE,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I look forward to positions in organizations that will give me a sense of stability and security",
    anchorType: AnchorType.SE,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I look forward to having work options and choices that help me to feel stable and secure in my work",
    anchorType: AnchorType.SE,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },

  // Life-Work Integration (LW)
  {
    text: "Balancing demands of my work, family and avocations are more important to me than seeking higher-level titles or positions of authority",
    anchorType: AnchorType.LW,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I have always sought out work opportunities that minimize interference with personal or family pursuits",
    anchorType: AnchorType.LW,
    anchorTimelineType: AnchorTimelineType.Current,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I would rather leave an organization than compromise my family interests and obligations",
    anchorType: AnchorType.LW,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },
  {
    text: "I dream of a career that reinforces my ability to integrate my work and personal pursuits",
    anchorType: AnchorType.LW,
    anchorTimelineType: AnchorTimelineType.Future,
    questionType: QuestionType.Anchors,
  },
];

export const GrowthQuestionBank: Question[] = [
  //
  // Growth Questions - Assessment 2
  //

  // Technical Functional (TF)
  {
    text: "I need to learn new skills and techniques in my specific field",
    anchorType: AnchorType.TF,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to improve my skills at gathering relevant information for my field",
    anchorType: AnchorType.TF,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to continuously develop my specialized knowledge and skills",
    anchorType: AnchorType.TF,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to improve my skills at keeping up with what my peers are doing",
    anchorType: AnchorType.TF,
    questionType: QuestionType.Growth,
  },

  // Autonomy (AU)
  {
    text: "I need to become better at figuring out how things work and how to fix things myself",
    anchorType: AnchorType.AU,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to be more comfortable operating on my own",
    anchorType: AnchorType.AU,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to be more comfortable staying on my own course even when others disagree",
    anchorType: AnchorType.AU,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to learn to work better on my own over long periods of time",
    anchorType: AnchorType.AU,
    questionType: QuestionType.Growth,
  },

  // Challenge And Risk (C&R)
  {
    text: "I need to become more able to tolerate the stress of risky decisions",
    anchorType: AnchorType.CR,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to be more willing to take on added risks in order to succeed",
    anchorType: AnchorType.CR,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to become better at finding ways to overcome difficult obstacles",
    anchorType: AnchorType.CR,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to be more comfortable in high risk situations",
    anchorType: AnchorType.CR,
    questionType: QuestionType.Growth,
  },

  // Entrepreneurial Creativity (EC)
  {
    text: "I need to become more open to sensing and pursuing new opportunities",
    anchorType: AnchorType.EC,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to spend more effort thinking of new solutions for all kinds of problems",
    anchorType: AnchorType.EC,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to increase my comfort level with uncertainty",
    anchorType: AnchorType.EC,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to be more comfortable dealing with surprises and complications",
    anchorType: AnchorType.EC,
    questionType: QuestionType.Growth,
  },

  // General Management (GM)
  {
    text: "I need to be more committed to my organization and its strategy",
    anchorType: AnchorType.GM,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to be more comfortable exercising authority and power",
    anchorType: AnchorType.GM,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to increase my skills in drawing people together to find common solutions",
    anchorType: AnchorType.GM,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to be more willing to exert my authority",
    anchorType: AnchorType.GM,
    questionType: QuestionType.Growth,
  },

  // Service, Dedication To A Cause (SV)
  {
    text: "I need to become more committed to helping others",
    anchorType: AnchorType.SV,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to increase my committment to causes that are right even if I don’t benefit financially",
    anchorType: AnchorType.SV,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to identify and work on worthwhile causes",
    anchorType: AnchorType.SV,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to see the importance of being helpful to others no matter what the circumstances",
    anchorType: AnchorType.SV,
    questionType: QuestionType.Growth,
  },

  // Stability And Security (SE)
  {
    text: "I need to become more willing to compromise in the interests of maintaining stability",
    anchorType: AnchorType.SE,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to be more willing to settle for my current position",
    anchorType: AnchorType.SE,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to avoid being drawn into tempting but risky assignments",
    anchorType: AnchorType.SE,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to accept that assigned work needs to be done no matter how dull or boring",
    anchorType: AnchorType.SE,
    questionType: QuestionType.Growth,
  },

  // Life-Work Integration (LW)
  {
    text: "I need to become more willing to prioritize personal interests, passions and hobbies",
    anchorType: AnchorType.LW,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to be more willing to integrate both the challenges of work and family",
    anchorType: AnchorType.LW,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to pursue the work possibilities that leave room for my other interests",
    anchorType: AnchorType.LW,
    questionType: QuestionType.Growth,
  },
  {
    text: "I need to be more willing to trade off some work for other pursuits",
    anchorType: AnchorType.LW,
    questionType: QuestionType.Growth,
  },
];

// get questions by question type from local storage, or shufled question bank
export const getQuestions = (
  questionType: QuestionType,
): Question[] | undefined => {
  switch (questionType) {
    case QuestionType.Anchors: {
      const anchorQuestions = localStorage.getItem(AnchorQuestionStorageString);
      if (anchorQuestions) {
        return JSON.parse(anchorQuestions);
      }

      const shuffledQuestions = shuffleQuestions(AnchorQuestionBank);
      localStorage.setItem(
        AnchorQuestionStorageString,
        JSON.stringify(shuffledQuestions),
      );

      return shuffledQuestions;
    }
    case QuestionType.Growth: {
      const growthQuestions = localStorage.getItem(GrowthQuestionStorageString);
      if (growthQuestions) {
        return JSON.parse(growthQuestions);
      }

      const shuffledQuestions = shuffleQuestions(GrowthQuestionBank);
      localStorage.setItem(
        GrowthQuestionStorageString,
        JSON.stringify(shuffledQuestions),
      );
      return shuffledQuestions;
    }
    default: {
      return;
    }
  }
};

// save questions to local storage by question type
export const saveQuestions = (
  questionType: QuestionType,
  questions: Question[],
) => {
  switch (questionType) {
    case QuestionType.Anchors: {
      localStorage.setItem(
        AnchorQuestionStorageString,
        JSON.stringify(questions),
      );
      break;
    }
    case QuestionType.Growth: {
      localStorage.setItem(
        GrowthQuestionStorageString,
        JSON.stringify(questions),
      );
      break;
    }
    default: {
      break;
    }
  }
};

export const clearQuestions = () => {
  localStorage.removeItem(AnchorQuestionStorageString);
  localStorage.removeItem(GrowthQuestionStorageString);
};
