import { AuthProvider, AuthProviderProps, User } from "oidc-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { PrimaryLayout } from "./components/PrimaryLayout";
import environment_ from "./env";
import { lazy, Suspense } from "react";
import { clearQuestions } from "./util/QuestionBank";
import { ApiIntegration } from "./util/ApiIntegration";

const Home = lazy(() => import("./pages/Home"));
const AboutTheAnchors = lazy(() => import("./pages/AboutTheAnchors"));
const AboutTheAuthors = lazy(() => import("./pages/AboutTheAuthors"));
const GettingStarted = lazy(() => import("./pages/GettingStarted"));
const MyAnchors = lazy(() => import("./pages/MyAnchors"));
const Evaluation = lazy(() => import("./pages/Evaluation"));
const Wiley = lazy(() => import("./pages/WileyAdmin"));
const AnchorsForAll = lazy(() => import("./pages/AnchorsForAll"));

const almAuthority = environment_.SCAR_ALM_AUTHORITY;
const almClientId = environment_.SCAR_ALM_CLIENTID;

export const BookShopUrl =
  "https://www.wiley.com/Career+Anchors+Reimagined%3A+Finding+Direction+and+Opportunity+in+the+Changing+World+of+Work%2C+5th+Edition-p-9781119899488?utm_source=scar-website&utm_medium=cta&utm_campaign=buythebook";
export const PinRedemptionUrl =
  "https://customer.wiley.com/CGI-BIN/lansaweb?procfun+shopcart4+SH4FN19+funcparms+PARMKEYG(L0060):HBTA";

function App(): JSX.Element {
  const queryClient = new QueryClient();

  const oidcConfig: AuthProviderProps = {
    onSignIn: async (user: User | null) => {
      if (!user) {
        return;
      }

      // You can't navigate from here, so we set a temp storage
      // to let the home page know we're back, authenticated
      // and should redirect to the my-anchors page
      localStorage.setItem("redirectFromLogin", "true");

      // clears the questions if they have previously gotten partially through it
      clearQuestions();
    },
    onSignOut: async () => {},
    authority: almAuthority,
    clientId: almClientId,
    redirectUri: `${window.location.origin}`,
    scope: "openid profile email",
    automaticSilentRenew: true,
    autoSignIn: false,
  };

  return (
    <AuthProvider {...oidcConfig}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Router>
          <Suspense fallback={<></>}>
            <Routes>
              <Route path="/evaluation/:context" element={<Evaluation />} />
              <Route path="/evaluation" element={<Evaluation />} />

              <Route path="/" element={<PrimaryLayout />}>
                <Route index element={<Home />} />

                <Route path="about-the-anchors" element={<AboutTheAnchors />} />
                <Route path="about-the-authors" element={<AboutTheAuthors />} />
                <Route path="getting-started" element={<GettingStarted />} />
                <Route path="wiley" element={<Wiley />} />
                <Route path="anchors-for-all" element={<AnchorsForAll />} />
                <Route path="my-anchors" element={<MyAnchors />} />
              </Route>
            </Routes>
          </Suspense>
          <ApiIntegration />
        </Router>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
