import { Container } from "./Container";
import { Logo } from "./Logo";
import { Legal } from "./Legal";

export const Footer = (): JSX.Element => {
  return (
    <footer className="z-10 bg-slate-50">
      <Container>
        <div className="py-16">
          <Logo className="mx-auto h-10 w-auto" />
          {/*
          <nav className="mt-10 text-sm" aria-label="quick links">
            <ul className="flex flex-wrap justify-center space-x-8 sm:space-x-12">
              <li>
                <a
                  href="https://www.wiley.com"
                  className="text-slate-400 hover:text-slate-500"
                >
                  Wiley
                </a>
              </li>
            </ul>
          </nav>
          */}
        </div>
        <Legal />
      </Container>
    </footer>
  );
};
