import { AnchorType } from "./Anchor";

export type Question = {
  weight?: number;
  text: string;
  anchorType: AnchorType;
  anchorTimelineType?: AnchorTimelineType;
  questionType: QuestionType;
  answered?: Date;
};

export enum QuestionType {
  Anchors = "anchors",
  Growth = "growth",
}

// the anchor timeline context type
export enum AnchorTimelineType {
  Current = "current",
  Future = "future",
}
