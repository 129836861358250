// the 8 anchors and their abbreviations
export enum AnchorType {
  TF = "Technical Functional",
  AU = "Autonomy",
  CR = "Challenge And Risk",
  EC = "Entrepreneurial Creativity",
  GM = "General Management",
  SV = "Service, Dedication To A Cause",
  SE = "Stability And Security",
  LW = "Life-Work Integration",
}

export interface Anchor {
  type: AnchorType;
  title: string;
  description: string;
}

export const Anchors: Anchor[] = [
  {
    type: AnchorType.TF,
    title: "Technical Functional (TF)",
    description:
      "A high value on this dimension means that you want to get better and better in your area of competence and would prefer your work to remain in that area of competence. What you consider to be your particular skills and areas of knowledge are a product of your talents, education and work experience. You are proud of them and it gives you an identity as ”the best” in some work area. That work area can be anything from being a great sales person or engineer or writer or teacher, or it can be in a craft such as electrical, mechanical or construction. But you are good at it and you value it, and if a promotion or new offer took you out of that area of work you might resist it, especially if it brought you into a managerial role in which you could no longer practice your area of competence.",
  },
  {
    type: AnchorType.AU,
    title: "Autonomy (AU)",
    description:
      "A high value on this dimension reflects your strong need to do things on your own, free of the constraints and rules that characterize most organizations and work projects. What you really want to hold on to is a work situation or job context that gives you the feeling of freedom and independence you need. At the extreme you might wish to be self-employed but many traditional organizational jobs also allow a great deal of this kind of freedom, such as in teaching, consulting, research and development, or even sales. If you are stuck in an organization, you want to remain in jobs that allow you flexibility regarding when, where, and how to work. You sometimes will turn down opportunities for promotion or advancement to a bigger project in order to retain your autonomy.",
  },
  {
    type: AnchorType.CR,
    title: "Challenge And Risk (C&R)",
    description:
      'A high value on this dimension means that work for you has to be perpetually challenging. You thrive on tackling the seemingly unsolvable problems, to winning-out over tough opponents, or on overcoming difficult obstacles. For you, the only meaningful reason for pursuing a job or career is that it continues to provide challenges, that it permits you to win out over the seemingly impossible. Some people find such pure challenge in intellectual kinds of work, such as the engineer who is only interested in impossibly difficult designs; some find the challenge in complex multifaceted situations, such as the strategy consultant who is only interested in clients who are about to go bankrupt and have exhausted all other resources; some find it in interpersonal competition, such as the professional athlete or the salesperson who defines every sale as either a win or a loss. Novelty, variety, and difficulty become ends in themselves, and if something is easy it becomes immediately boring. For many the challenge and risk of capital markets finance, for instance private equity and venture capital, are very appealing in that "financial engineering" involves huge capital risks and huge rewards for succeeding in high stress situations.',
  },
  {
    type: AnchorType.EC,
    title: "Entrepreneurial Creativity (EC)",
    description:
      "A high value on this dimension reflects your need to create an organization or enterprise of your own, built on your own creative abilities and your own willingness to take risks and to overcome obstacles. You want to prove to the world that you can create an enterprise that is the result of your own effort. You may be working for others in an organization while you are learning and assessing future opportunities, but you will go out on your own as soon as you feel you can manage it. You want your enterprise to be financially successful as proof of your abilities. You measure yourself by the size of the enterprise and its success. This need is so strong that you will tolerate many failures throughout your career in the search for that ultimate success. You may also find that once an enterprise is successful you may want to move on to try to build get another enterprise.",
  },
  {
    type: AnchorType.GM,
    title: "General Management (GM)",
    description:
      "A high value on this dimension implies that you have a desire to manage broadly and pull together the various elements of an organization or project. What you desire is the opportunity to climb to a level high enough in an organization to enable you to direct the efforts of others across functions and to be responsible for the output of a particular unit of the organization. You want to be responsible and accountable for total results, and you identify your own work with the success of the organization for which you work. If you are presently in a technical or functional area, you view that as a necessary learning experience and may even accept a high-level management job in that function. However, your ambition is to get to a generalist job as soon as possible. You want to be able to attribute the success of your organization or project to your own managerial capabilities based on analytical skills, interpersonal and group skills, and the emotional capacity to deal with high levels of responsibility.",
  },
  {
    type: AnchorType.SV,
    title: "Service, Dedication To A Cause (SV)",
    description:
      "A high value on this dimension means that you want your occupation and your daily work to mean something, that you want to pursue work that achieves something of value, such as making the world a better place to live, solving the environmental problems of how to adapt to global warming, improving harmony among people, helping others, improving people's safety, curing diseases through new products, and so on. You would pursue such opportunities even if it meant changing organizations, and you would not accept transfers or promotions that would take you out of work that fulfills those values.",
  },
  {
    type: AnchorType.SE,
    title: "Stability And Security (S&S)",
    description:
      "A high value on this dimension implies that you want your job to provide you with a feeling of security, stability and predictability for the long term. You are looking for an environment that is not too risky, and that provides a steady income and the assurance that you will have the same job and the same salary for a long time. You may not have a particular career goal, but rather are looking for a job that is secure, that requires minimal stress and risk so that you can focus on doing a good job and not worry about the future. You may also be looking for opportunities to build a career and a reputation with a single employer, and you will stay with that employer as long as it remains loyal to you.",
  },
  {
    type: AnchorType.LW,
    title: "Life-Work Integration (LW)",
    description:
      "A high value on this dimension reflects your desire to have work and life balance, and a need to have time for yourself and your family. You are looking for a job or career that gives you the flexibility to make time for yourself, and to be present in your own life, as well as in the lives of your family and friends. You want to be able to take time off for vacations and to pursue personal interests, be it travel, hobbies or learning. You may also be looking for a job that is not too demanding or demanding for too long hours and can leave you enough time for your life outside work. You may be making trade-offs between the potential for higher earnings in some jobs and the need for more balance in your life.",
  },
];
