import development from "./development";
import production from "./production";
import qa from "./qa";
import local from "./local";

export interface EnvironmentSettings {
  SCAR_API_BASE: string;
  SCAR_ALM_AUTHORITY: string;
  SCAR_ALM_CLIENTID: string;
}

let environment: EnvironmentSettings;
switch (window.location.hostname) {
  case "trade-frontend.dev.scar.wiley.host": {
    environment = { ...development };
    break;
  }
  case "qa.careeranchorsonline.com":
  case "trade-frontend.qa.scar.wiley.host": {
    environment = { ...qa };
    break;
  }

  case "www.careeranchorsonline.com":
  case "trade-frontend.prod.scar.wiley.host": {
    environment = { ...production };
    break;
  }
  case "localhost": {
    environment = { ...local };
    break;
  }
  default: {
    // not recognised
    console.log("Environment not recognised", window.location.hostname);
    environment = { ...development };
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const environment_: EnvironmentSettings = environment;
export default environment_;
