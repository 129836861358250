import imgLogo from "../images/SCAR-logo.png";

type LogoProperties = {
  className?: string;
};

export const Logo = (properties: LogoProperties): JSX.Element => {
  return (
    <img
      loading="lazy"
      src={imgLogo}
      className={properties.className}
      alt="CAREER ANCHORS REIMAGINED"
    />
  );
};
