import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Popover } from "@headlessui/react";

type HeaderNavLinkProperties = {
  to?: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const HeaderNavLink = (
  properties: HeaderNavLinkProperties,
): JSX.Element => {
  const { to, children, className, onClick } = properties;

  return (
    <NavLink
      className={({ isActive }) => {
        return clsx(
          "inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900",
          className,
          isActive && "bg-slate-100 text-slate-900",
        );
      }}
      to={to || ""}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};

type MobileNavLinkProperties = {
  to?: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const MobileNavLink = (
  properties: MobileNavLinkProperties,
): JSX.Element => {
  return (
    <Popover.Button
      as={NavLink}
      onClick={properties.onClick}
      to={properties.to || ""}
      className="block w-full p-2"
    >
      {properties.children}
    </Popover.Button>
  );
};
