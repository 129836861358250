import clsx from "clsx";

type MobileNavIconProperties = {
  open: boolean;
};

export const MobileNavIcon = (
  properties: MobileNavIconProperties,
): JSX.Element => {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          "origin-center transition",
          properties.open && "scale-90 opacity-0",
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          "origin-center transition",
          !properties.open && "scale-90 opacity-0",
        )}
      />
    </svg>
  );
};
