import clsx from "clsx";

type ContainerProperties = {
  className?: string;
  children: React.ReactNode;
};

export const Container = (properties: ContainerProperties): JSX.Element => {
  return (
    <div
      className={clsx(
        "mx-auto max-w-7xl px-4 sm:px-6 lg:px-8",
        properties.className,
      )}
    >
      {properties.children}
    </div>
  );
};
