import { Popover, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "./Fields/Button";
import { Logo } from "./Logo";
import { Container } from "./Container";
import { HeaderNavLink, MobileNavLink } from "./NavLinks";
import { MobileNavIcon } from "./MobileNavIcon";
import { useAuth } from "oidc-react";
import { BookShopUrl } from "../App";
import { getProfile } from "../util/ApiIntegration";
import { Profile } from "../types/Profile";

export const MobileNavigation = (): JSX.Element => {
  const { signOut, userData } = useAuth();

  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <>
              <MobileNavLink to={userData ? "/my-anchors" : "/"}>
                Find My Anchors
              </MobileNavLink>
              <hr className="m-2 border-slate-300/40" />

              <MobileNavLink to="/about-the-anchors">
                About the Anchors
              </MobileNavLink>
              <MobileNavLink to="/about-the-authors">
                About the Authors
              </MobileNavLink>
              <MobileNavLink to="/getting-started">
                Getting Started
              </MobileNavLink>

              {userData && (
                <>
                  <hr className="m-2 border-slate-300/40" />

                  <MobileNavLink
                    onClick={() => {
                      signOut();
                    }}
                  >
                    Logout
                  </MobileNavLink>
                </>
              )}
            </>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
};

export const Header = (): JSX.Element => {
  const { signIn, signOut, userData } = useAuth();
  const navigate = useNavigate();

  const [profile, setProfile] = useState<Profile>();
  useEffect(() => {
    const checkProfile = async () => {
      const loadedProfile = await getProfile();
      if (loadedProfile) {
        setProfile(loadedProfile);
      } else {
        // eslint-disable-next-line unicorn/no-useless-undefined
        setProfile(undefined);
      }
    };
    checkProfile();
  }, [userData]);

  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" aria-label="Home">
              <Logo className="h-14 w-auto" />
            </Link>
            <div className="hidden md:flex md:gap-x-6">
              {userData && (
                <HeaderNavLink to="/my-anchors">My Anchors</HeaderNavLink>
              )}

              <HeaderNavLink to="/getting-started">
                Getting Started
              </HeaderNavLink>
              <HeaderNavLink to="/about-the-anchors">
                About the Anchors
              </HeaderNavLink>
              <HeaderNavLink to="/about-the-authors">
                About the Authors
              </HeaderNavLink>

              {/* does it have more than 0 tokens? */}
              {userData && profile && !!profile.tokens && (
                <HeaderNavLink to="/anchors-for-all">
                  Anchors For All
                </HeaderNavLink>
              )}

              {/* does the user email end with @wiley.com? */}
              {userData && userData?.profile?.email?.endsWith("@wiley.com") && (
                <HeaderNavLink to="/wiley">Wiley Admin</HeaderNavLink>
              )}
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <>
              {userData && (
                <div className="hidden md:block">
                  <HeaderNavLink
                    onClick={() => {
                      signOut();
                    }}
                  >
                    Logout
                  </HeaderNavLink>
                </div>
              )}

              {!userData && (
                <>
                  <div className="hidden md:block">
                    <HeaderNavLink
                      onClick={() => {
                        window.open(BookShopUrl, "_blank");
                      }}
                    >
                      Get the Book
                    </HeaderNavLink>
                  </div>
                  <Button
                    onClick={() => {
                      if (userData) {
                        navigate("/my-anchors", { replace: true });
                      } else {
                        signIn();
                      }
                    }}
                    variant="solidScarBlue"
                  >
                    <span>Find my Anchors</span>
                  </Button>
                </>
              )}

              <div className="-mr-1 md:hidden">
                <MobileNavigation />
              </div>
            </>
          </div>
        </nav>
      </Container>
    </header>
  );
};
