import { EnvironmentSettings } from ".";

const development: EnvironmentSettings = {
  SCAR_API_BASE: "https://scar-backend-service.dev.tc.wiley.host/",
  SCAR_ALM_AUTHORITY:
    "https://alm-external-sso.qa.alm.wiley.host/auth/realms/wiley/",
  SCAR_ALM_CLIENTID: "b2b770b1-8a81-4b84-a914-15ee43e3e50a",
};

export default development;
